<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>学术系统</el-breadcrumb-item>
            <el-breadcrumb-item>裁判系统</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">个人赛裁判系统</span>
                </el-col>
            </el-row>
            <div class="schedule-list">
                <div
                    class="schedule-item"
                    v-for="(itemData, index) in scheduleCardList"
                    v-bind:key="index"
                >
                    <gameStageBackview
                        :itemData="itemData"
                        :key="index"
                        :itemIndex="index"
                        @itemClicked="scheduleCardTap"
                    ></gameStageBackview>
                </div>
            </div>
        </el-card>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">团体赛裁判系统</span>
                </el-col>
            </el-row>
            <div class="schedule-list">
                <div
                    class="schedule-item"
                    v-for="(itemData, index) in itsoGameCardList"
                    v-bind:key="index"
                >
                    <gameStageBackview
                        :itemData="itemData"
                        :key="index"
                        :itemIndex="index"
                        @itemClicked="itsoCardTap"
                    ></gameStageBackview>
                </div>
            </div>
        </el-card>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">IBC裁判系统</span>
                </el-col>
            </el-row>
            <div class="schedule-list">
                <div
                    class="schedule-item"
                    v-for="(itemData, index) in ibcScheduleCardList"
                    v-bind:key="index"
                >
                    <gameStageBackview
                        :itemData="itemData"
                        :key="index"
                        :itemIndex="index"
                        @itemClicked="ibcScheduleCardTap"
                    ></gameStageBackview>
                </div>
            </div>
        </el-card>
        <el-dialog :visible.sync="repechageDialogVisible" width="30%">
          <div class="tool-con">
            <div class="tool-item" v-for="(item,index) in toolList" :key="item.id" @click="toolItemClick(item,index)">
              <div class="tool-item-icon" :class="'coloricon-reverse'+(index+1)"><i class="icon iconfont" :class="item.icon"></i></div>
              <div class="tool-item-title">{{ item.title }}</div>
            </div>
          </div>
        </el-dialog>
    </div>
</template>
<script>
import { } from '@/http/api'
export default {
  data () {
    return {
      repechageDialogVisible: false,
      ibcScheduleCardList: [
        {
          scheduleName: '第一赛季',
          scheduleId: 1,
          backGround: '#57a3f7',
          icon: 'icon-chujia',
          flag: this.$chnEngStatusCode.academic_ibc_management
        },
        {
          scheduleName: '第二赛季',
          scheduleId: 2,
          backGround: '#57a3f7',
          icon: 'icon-chujia',
          flag: this.$chnEngStatusCode.academic_ibc_management
        },
        {
          scheduleName: '第三赛季',
          scheduleId: 3,
          icon: 'icon-chujia',
          backGround: '#57a3f7',
          flag: this.$chnEngStatusCode.academic_ibc_management
        },
        {
          scheduleName: '第四赛季',
          scheduleId: 4,
          icon: 'icon-chujia',
          backGround: '#57a3f7',
          flag: this.$chnEngStatusCode.academic_ibc_management
        }
      ],
      toolList: [
        {
          id: 1,
          title: '裁判系统',
          icon: 'icon-saichejingsu',
          path: ''
        },
        {
          id: 2,
          title: '复活赛排行',
          icon: 'icon-tisheng',
          path: ''
        }
      ],
      scheduleCardList: [
        {
          scheduleName: '城市半决选',
          icon: 'icon-chujia',
          scheduleId: '4',
          promotionNum: 0,
          signUpNum: 0,
          historyPromotionNum: 0,
          historySignUpNum: 0,
          percent: 0,
          historyPercent: 0,
          backGround: '#febf3d',
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_h',
          flag: this.$chnEngStatusCode.academic_spbcn_management
        }, {
          scheduleName: '城市总决选',
          icon: 'icon-chujia',
          scheduleId: '5',
          promotionNum: 0,
          signUpNum: 0,
          historyPromotionNum: 0,
          historySignUpNum: 0,
          percent: 0,
          historyPercent: 0,
          backGround: '#52c1f5',
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem',
          flag: this.$chnEngStatusCode.academic_spbcn_management
        }, {
        //   scheduleName: '网络总（4轮）',
        //   netMatchType: '4',
        //   icon: 'icon-chujia',
        //   scheduleId: '6',
        //   promotionNum: 0,
        //   signUpNum: 0,
        //   historyPromotionNum: 0,
        //   historySignUpNum: 0,
        //   percent: 0,
        //   historyPercent: 0,
        //   backGround: '#14b8a5',
        //   path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_net',
        //   flag: this.$chnEngStatusCode.academic_spbcn_management
        // }, {
          scheduleName: '网络总（5轮）',
          netMatchType: '5',
          icon: 'icon-chujia',
          scheduleId: '6',
          promotionNum: 0,
          signUpNum: 0,
          historyPromotionNum: 0,
          historySignUpNum: 0,
          percent: 0,
          historyPercent: 0,
          backGround: '#14b8a5',
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_net',
          flag: this.$chnEngStatusCode.academic_spbcn_management
        },
        {
          icon: 'icon-chujia',
          scheduleName: '国赛有组别-晋级赛',
          scheduleId: '19',
          promotionNum: 0,
          signUpNum: 0,
          historyPromotionNum: 0,
          historySignUpNum: 0,
          percent: 0,
          historyPercent: 0,
          backGround: '#fb6260',
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_group_promote',
          flag: this.$chnEngStatusCode.academic_spbcn_management
        },
        {
          icon: 'icon-chujia',
          scheduleName: '国赛有组别-冠亚季',
          scheduleId: '7',
          promotionNum: 0,
          signUpNum: 0,
          historyPromotionNum: 0,
          historySignUpNum: 0,
          percent: 0,
          historyPercent: 0,
          backGround: '#fb6260',
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_group',
          flag: this.$chnEngStatusCode.academic_spbcn_management
        },
        {
        //   icon: 'icon-chujia',
        //   scheduleName: '国赛复活赛',
        //   scheduleId: '9',
        //   promotionNum: 0,
        //   signUpNum: 0,
        //   historyPromotionNum: 0,
        //   historySignUpNum: 0,
        //   percent: 0,
        //   historyPercent: 0,
        //   backGround: '#fb6260',
        //   flag: this.$chnEngStatusCode.academic_spbcn_management
        // }, {
          scheduleName: '国赛无组别',
          icon: 'icon-chujia',
          scheduleId: '8',
          promotionNum: 0,
          signUpNum: 0,
          historyPromotionNum: 0,
          historySignUpNum: 0,
          percent: 0,
          historyPercent: 0,
          backGround: '#8167f5',
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_final',
          flag: this.$chnEngStatusCode.academic_spbcn_management
        }
      ],
      itsoGameCardList: [
        {
          scheduleName: '全国半决选',
          icon: 'icon-chujia',
          scheduleId: '17',
          promotionNum: 0,
          signUpNum: 0,
          historyPromotionNum: 0,
          historySignUpNum: 0,
          percent: 0,
          historyPercent: 0,
          backGround: '#febf3d',
          flag: this.$chnEngStatusCode.academic_itso_management
        },
        {
          scheduleName: '全国总决选',
          icon: 'icon-chujia',
          scheduleId: '18',
          promotionNum: 0,
          signUpNum: 0,
          historyPromotionNum: 0,
          historySignUpNum: 0,
          percent: 0,
          historyPercent: 0,
          backGround: '#52c1f5',
          flag: this.$chnEngStatusCode.academic_itso_management
        }
      ]
    }
  },
  methods: {
    scheduleCardTap (scheduleData) {
      if (scheduleData.scheduleId === '9') {
        this.repechageDialogVisible = true
      } else {
        if (!scheduleData.path) return
        this.$router.push(
          {
            path: scheduleData.path,
            query: {
              matchTypeName: scheduleData.scheduleName,
              raceSchedule: scheduleData.scheduleId,
              netMatchType: scheduleData.netMatchType ? scheduleData.netMatchType : '0'
            }
          }
        )
      }
    },
    ibcScheduleCardTap (scheduleData) {
      console.log('scheduleData', scheduleData)
      this.$router.push(
        {
          path: '/academic_Management/academic_font_management/academic_ibc_refereesystem',
          query: {
            ibcSeason: scheduleData.scheduleId
          }
        }
      )
    },
    itsoCardTap (scheduleData) {
      console.log('scheduleData', scheduleData)
      this.$router.push(
        {
          path: '/academic_Management/academic_font_management/academic_itso_refereesystem',
          query: {
            scheduleId: scheduleData.scheduleId
          }
        }
      )
    },
    toolItemClick (item, index) {
      if (item.id === 1) {
        this.$router.push({
          path: '/academic_Management/academic_font_management/chtyFinaalRefereesystem/academic_schdual_refereesystem_revive'
        })
        // 跳转裁判系统
      } else { // 跳转复活赛排名
        this.$router.push(
          {
            path: '/chtyFinaalRefereesystem/academic_examination_reviveRank'
          }
        )
      }
    }
  }
}
</script>
<style lang="less" scoped>
.repechage-dialog {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tool-con{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tool-item{
    text-align: center;
    cursor: pointer;
    flex: 1;
  }
  .tool-item-icon{
    width: 44px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 8px;
    .iconfont{
      font-size: 24px;
    }
  }
  .tool-item-title{
    color: #333;
    font-size: 14px;
  }
}
</style>
